<template>
  <form @submit.prevent="lookUpSubmit" class="lookup-form-container">
    <JhakaasInput 
    :inputType="$t(inputType)" 
    :label="$t(newInputPlaceHolder)" 
    inputId="userEmail"
      reference="userEmailRef" 
      :helperText="formError" 
      :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      autocomplete="false" 
      :value="userInput" 
      :startIcon="true"
      :marginStart="renderCountryCodeUI" @keydown.space.prevent required
      @onChange="(value) => (userInput = value)">
      <template v-slot:startIcon>
        <div id="input-container" v-if="renderCountryCodeUI"
          class="input-container-lookup">
          <div id="countrycode" style="display:flex; gap:5px">
            <p class="tele-code" v-if="teleCode">{{ teleCode }}</p>
            <img class="img-animate" id="arrow" src="@/assets/icons/down_arrow.svg" alt />
          </div>
        </div>
      </template>
    </JhakaasInput>
    <div class="lookup-form-container-dropselect" v-if="isDropSelect" dir="ltr">
      <div class="lookup-form-container-dropselect-search" id="countrylist-searchtext">
        <input type="text" class="lookup-form-container-dropselect-search-text" v-model="searchText"
          :placeholder="`${searchCountryPlaceholder}`" @input="filterCountry" required />
      </div>
      <div class="lookup-form-container-dropselect-container">
        <div class="lookup-form-container-dropselect-container-item" id="countrylist-item"
          v-for="(item, index) in countryList" :key="index" @click="selectCode(item.dial_code)">
          <div class="lookup-form-container-dropselect-container-item-span" @mouseover="hover($event)"
            @mouseleave="out($event)">
            <span>{{ item.dial_code }}</span>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="lookup-form-container-continue-button">
      <button class="button-primary" id="lookupbutton" type="submit" :disabled="loader">
        <Loading class="loader" v-if="loader" :size="'16px'"></Loading>
        <span>{{$t("continue")}}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="arrow feather feather-arrow-right">
          <line x1="5" y1="12" x2="19" y2="12"></line>
          <polyline points="12 5 19 12 12 19"></polyline>
        </svg>
      </button>
    </div>
  </form>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { _providerId } from "@/provider-config.js";
import { mapGetters } from "vuex";
import { store } from "@/store/store";
import { showSnackBar } from "@/utilities";

export default {
  name: "lookup",
  props: {
    input: {
      type: String,
    },
    changeBtnInputVariable: {
      type: String,
    }
  },
  data() {
    return {
      i18nEmailIdLabel: "",
      i18nMobilenumberLabel: "",
      searchCountryPlaceholder: "",
      payloadData: "",
      userInput: "",
      formError: "",
      switcher: "Mobile",
      field: "Mobile",
      teleCode: "+91",
      countrycode: "Please Select Country Code",
      countryCodes: [],
      digitCount: 0,
      isDropSelect: false,
      searchText: "",
      countryList: [],
      countryListCopy: [],
      providerUniqueId: _providerId,
      localDisplayLang: null,
      renderCountryCodeUI: true, //we are using the only moible login so its enable if not by conditional we have to disable
      newInputPlaceHolder: this.$t("Enter Mobile Number"),
      inputType: "number",
      loader: false, //loader in side continue button
    };
  },
  computed: {
    ...mapGetters(["getRtl", "getCountry", "getCountryCodesList"]),
  },
  watch: {
    getData(renderCountryCodeUI) {
      console.log("getValue", renderCountryCodeUI)
    },
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = "";
        }, 5000);
      }
    },
    userInput(val) {
      if (val) {
        this.verifyInput(val);
      } else {
        this.verifyInput("xyz");
        this.searchText = "";
        this.filterCountry();
        this.fetchCurrentCountryCode();
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.getCountryList();

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    eventBus.$on("socialLoginToggleIcon", (data) => {
      // Call the toggleCountryCode() function
      this.toggleCountryCode(data);
    });
    eventBus.$on("auth-close", () => {
      this.$emit("closePopup");
    });

    eventBus.$on("lookup-response", (response) => {
      if (response.reason) {
        this.formError = response.errorcode == 6066 ? "" : showSnackBar(this.$t(response.reason), this.$t('DISMISS'));
      } else {
        let info = {
          input: this.field === "Mobile" ? this.teleCode + this.userInput : this.userInput,
          inputtype: this.field,
        };

        if (response.success) {
          if (this.field === "Mobile") {
            info.formType = "MobileLogin";
          } else if (this.field === "Email") {
            info.formType = "login";
          }
        } else { 
          if (this.field === "Mobile") {
            info.formType = "MobileSignUp";
          } else if (this.field === "Email") {
            info.formType = "signup";
          }
        }
        setTimeout(() => {
          eventBus.$emit("signup-lookup-res", response)
        }, 750);
        this.$emit("change", info);
      }
    });
    eventBus.$on("loader", (state) => {
      this.loader = state;
    });

  },
  components: {
    JhakaasInput: () => import("../../../utils/JhakaasInput.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mounted() {
    // this.renderCountryCodeUI = true;
    setTimeout(() => {
      if (this.changeBtnInputVariable == "Email") {
        this.renderCountryCodeUI = true
        this.toggleCountryCode()
      } else {
        this.renderCountryCodeUI = true
      }
    }, 1000);
    eventBus.$on("social-captch-event", (data) => {
      

      let info = {
        data,
      };
      info.formType = "socialCaptcha";
      // this.formInfo = info;
      this.$emit("change", info);
    });

    this.i18nEmailIdLabel = this.$t("email id");
    this.i18nMobilenumberLabel = this.$t("mobilenumber");
    this.searchCountryPlaceholder = this.$t("search country");

    let self = this;

    window.addEventListener("mouseup", function (event) {
      if (self.field === "Mobile") {
        if (event.target.parentNode.id === "countrycode") {
          self.toggleDropSelect();
        } else if (
          !event.target.parentNode.id ||
          event.target.parentNode.id === "input-container"
        ) {
          if (self.isDropSelect) {
            self.isDropSelect = false;
            self.searchText = "";
            self.filterCountry();
            document.getElementById("arrow").style.transform = "rotate(0deg)";
          }
        }
      }
    });

    this.lookUpform();

   
  },
  methods: {
    getCountryList() {
      this.countryList = this.getCountryCodesList;
      this.countryListCopy = [...this.countryList];
      this.fetchCurrentCountryCode();
    },
    toggleCountryCode(data) {
      this.renderCountryCodeUI = !this.renderCountryCodeUI;
      this.newInputPlaceHolder = this.renderCountryCodeUI ? this.$t("Enter Mobile Number") : this.$t("Enter Email ID");
      this.userInput = "";
      this.formError = "";
      if (!this.renderCountryCodeUI) {
        this.field = "Email";
        this.inputType = "text"
      } else {
        this.field = "Mobile";
        this.inputType = "number"
      }
      eventBus.$emit('countryCodeToggled', this.renderCountryCodeUI);
    },
    fetchCurrentCountryCode() {
      let index = this.countryList.findIndex((element) => {
        return element.code === this.getCountry.CountryCode;
      });

      if (index > -1) {
        this.teleCode = this.countryList[index].dial_code
          ? this.countryList[index].dial_code
          : "";
      }
    },
    lookUpform() {
      this.userInput = "";
      if (this.input) {
        this.userInput = this.input;
      }
    },
    verifyInput(value) {
      if (this.renderCountryCodeUI) {
        if (/^\d{0,15}$/.test(value)) {
          this.formError = "";
        } else {
          // this.formError = this.$t("Invalid Input");
          this.userInput = "";
        }
      }
      let digits = /^[0-9]+$/;
      let alphaNumeric = /^[0-9a-zA-Z]+$/;
      let emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      

      if (digits.test(value)) {
        this.field = "Mobile";
        // document.getElementById("userInput").setAttribute("maxLength", 15);
        this.formError = "";
      } else if (alphaNumeric.test(value)) {
        this.field = "Email";
        this.isDropSelect = false;
        document.getElementById("userInput").removeAttribute("maxLength");
        this.formError = "";
      } else if (emailPattern.test(value)) {
        this.field = "Email";
        this.isDropSelect = false;
        document.getElementById("userInput").removeAttribute("maxLength");
        this.formError = "";
      } else {
        // this.formError = this.$t("Invalid Input");
      }
    },

    countryCodeSelect(code) {
      this.teleCode = code;
      document.getElementById("input-container").style.display = "block";
      document.getElementById("expanded-container").style.display = "none";
    },
    toggleDropSelect() {
      this.isDropSelect = !this.isDropSelect ? true : false;
      if (this.isDropSelect) {
        document.getElementById("arrow").style.transform = "rotate(-180deg)";
      } else {
        document.getElementById("arrow").style.transform = "rotate(0deg)";
      }
    },
    selectCode(code) {
      
      this.teleCode = "";
      this.teleCode = code;
      this.isDropSelect = false;

      document.getElementById("arrow").style.transform = "rotate(0deg)";
    },
    hover(e) {
      if (
        e.target.parentNode.className ===
        "lookup-form-container-dropselect-container-item"
      ) {
        var color = "var(--clr-saina-country-code-select)";
       e.target.parentNode.style.backgroundColor = color;

      }
    },
    out(e) {
      if (
        e.target.parentNode.className ===
        "lookup-form-container-dropselect-container-item"
      ) {
        var color = "var(--clr-saina-country-fetch)";
        e.target.parentNode.style.backgroundColor = color;
      }
    },
    filterCountry() {
      let searchText = this.searchText.toLowerCase();
      if (
        searchText === "" ||
        searchText === null ||
        searchText === undefined
      ) {
        this.countryList = [...this.countryListCopy];
        return;
      }

      let arr = [];
      arr = this.countryListCopy.filter((element) => {
        return (
          element.name.toLowerCase().includes(searchText) ||
          element.dial_code.toLowerCase().includes(searchText)
        );
      });

      this.countryList = arr;
    },

    lookUpSubmit() {
      let payload = {};
      if (this.userInput === "") {
        let error = "";
        if (this.renderCountryCodeUI) {
          error = this.$t("Please enter a valid phone number");
        }
        else {
          error = this.$t("Please fill out this field");
        }
        this.formError = error;
        return;
      }
      if (
        this.userInput.slice(0, 1) !== "+" &&
        this.userInput.indexOf("%") <= 0
      ) {
        if (this.field === "Email") {

          if (!this.validateEmail(this.userInput)) return;
          if (this.renderCountryCodeUI) {
            this.formError = this.$t("Please enter a valid phone number");
            return;
          }
          payload.email = this.userInput.trim();
        } else if (this.field === "Mobile") {
          if (!this.renderCountryCodeUI) {
            this.formError = this.$t("Please enter a valid email adddress");
            return;
          }
          //if (!this.validateEmail(this.userInput)) return;
          payload.mobileno = this.teleCode + this.userInput;
        }
        eventBus.$emit("lookUp", payload);
      //   store.dispatch("lookUp", payload).then((response) => {
      //   eventBus.$emit("loader", false);
      //   if (response.data) {
      //     eventBus.$emit("lookup-response-mobile", response.data);
      //   }
      // })
      // .catch((error) => console.log(error));
      } else if (this.userInput.slice(0, 1) === "+") {
        this.formError = "Please remove '+' from Input";
      } else if (this.userInput.indexOf("%") > -1) {
        this.formError = "Valid Email Required";
      } else {
        this.formError = "Please Enter Email ID";
      }

    },

  },
  mixins: [Utility],
  beforeDestroy() {
    eventBus.$off("mouseup");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./lookUp.scss"
</style>
