<template>
  <div class="login-form">
    <!-- Captcha -->
    <form class="form-group" @submit.prevent="loginSubmit">
      <div v-if="formError" class="error-container">
        <p class="error">{{ formError }}</p>
      </div>
      <div v-if="isCaptchaEnabled && captchaImage">
        <label class="login-label">{{ $t("Please enter the numbers you see in the image") }}</label>
        <div
          v-if="captchaImage"
          :style="{
            backgroundImage: 'url(' + captchaImage + ')',
            'background-repeat': 'no-repeat',
            'background-color': 'white',
            width: '150px',
          }"
          class="captcha-image"
        ></div>
      </div>

      <div v-if="isCaptchaEnabled">
        <button @click="getAnotherCaptcha" class="button-link" type="button">
          {{ $t("Click to refresh if you can not read") }}
        </button>
      </div>

      <div class="form-control" v-if="isCaptchaEnabled">
        <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
          <!-- <label class="login-label">{{ $t("The result") }}</label>
          <span class="required">*</span> -->
        </div>
        <div class="input-container" dir="ltr">
          <input
            id="loginCaptcha"
            type="text"
            v-model="loginCaptcha"
            :placeholder="`${this.$t('Enter captcha')}`"
            @keydown.space.prevent
          />
        </div>
      </div>
      <br />
      <div class="login-button">
        <button class="button-primary login" type="submit">
          {{ $t("LOGIN") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    formData: {
      type: Object,
    },
  },

  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
  },

  computed: {
    ...mapGetters(["appConfig"]),
  },

  data() {
    return {
      captchaImage: true,
      isCaptchaEnabled: true,
      localDisplayLang: null,
      captchaRefId: null,
      loginCaptcha: null,
      formError: null,
    };
  },

  created() {
    

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("social-login-response-captcha-error", (response) => {
      

      this.formError = this.$t(response.data.reason);
    });

    if (this.formData && this.formData.data && this.formData.data.isSocialSignup) {
      this.getCaptchaImage();
    } else {

      if(this.formData && this.formData.data && this.formData.data.response && this.formData.data.response.data && this.formData.data.response.data.success) {
        const successData = this.formData.data.response.data.success;
        this.captchaImage = successData.payload;
        this.captchaRefId = successData.referenceid;
      }
      
    }
  },
  methods: {
    ...mapActions(["getCaptcha"]),

    loginSubmit() {
      

      if (!this.loginCaptcha) {
        this.formError = this.$t("Please enter Captcha");
        return;
      }

      const newPayload = {
        ...this.formData.data.payload,
      };
      newPayload.params.captcha = JSON.stringify({
        text: this.loginCaptcha,
        referenceid: this.captchaRefId,
      });

      if (this.formData.data.isSocialSignup) {
        eventBus.$emit("subscriberSignup", newPayload);
      } else {
        eventBus.$emit("subscriberLogin", newPayload);
      }
    },

    getAnotherCaptcha() {
      this.getCaptchaImage();
    },

    getCaptchaImage() {
      this.getCaptcha()
        .then((data) => {
          if (data.reason) {
            this.formError = this.$t(data.reason);
            return;
          }

          

          this.captchaImage = data.payload;
          this.captchaRefId = data.referenceid;
        })
        .catch((error) => {
          console.log("Captcha error -> ", error);
        });
    },
  },

  beforeDestroy() {
    eventBus.$off("social-login-response-captcha-error");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./socialCaptcha.scss"

</style>
