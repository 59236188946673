<template>
  <!--

<div v-if="formError" :dir="localDisplayLang === 'ara' ? 'ltr' : ''" class="error-container">
          <p class="error">{{ formError }}</p>
        </div>


<div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            <label class="login-label">{{ $t("Email/mobile") }}</label>
           
            <span class="required">*</span>
          </div>
          <div id="input-container" class="lookup-form-container-input-container" dir="ltr">
            <div v-if="field === 'Mobile'" id="countrycode" class="lookup-form-container-input-container-select-code">
              <input
                type="text"
                v-model="teleCode"
                placeholder="+91"
                :class="[localDisplayLang === 'ara' ? 'rightPad' : '']"
                disabled
              />
              <img id="arrow" src="@/assets/icons/down_arrow.svg" alt />
            </div>
            <input
              id="userEmail"
              class="lookup-form-container-input-container-inputtext"
              type="text"
              dir="ltr"
              v-model="userInput"
              autocomplete="false"
              @keydown.space.prevent
            />
          </div>


  -->

  <div class="lookup-form-container">
    <form @submit.prevent="lookUpSubmit">
      <div class="form-group">
        <JhakaasInput
          inputType="text"
          :label="$t('Email/mobile')"
          inputId="userEmail"
          reference="userEmailRef"
          :helperText="formError"
          :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
          autocomplete="false"
          :value="userInput"
          :marginStart="renderCountryCodeUI"
          @keydown.space.prevent
          required
          @onChange="(value) => (userInput = value)"
        >
          <template v-slot:startIcon>
            <div
              id="input-container"
              v-if="renderCountryCodeUI"
              style="padding-right:10px"
              class="input-container-lookup"
            >
              <div id="countrycode" style="display:flex; gap:5px">
                <p class="tele-code" v-if="teleCode">{{ teleCode }}</p>

                <img
                  class="img-animate"
                  id="arrow"
                  src="@/assets/icons/down_arrow.svg"
                  alt
                />
              </div>
            </div>
          </template>
        </JhakaasInput>

        <div class="relative">
          <div
            class="lookup-form-container-dropselect"
            v-if="isDropSelect"
            dir="ltr"
          >
            <div
              class="lookup-form-container-dropselect-search"
              id="countrylist-searchtext"
            >
              <input
                type="text"
                class="lookup-form-container-dropselect-search-text"
                v-model="searchText"
                :placeholder="`${searchCountryPlaceholder}`"
                @input="filterCountry"
                required
              />
            </div>
            <div class="lookup-form-container-dropselect-container">
              <div
                class="lookup-form-container-dropselect-container-item"
                id="countrylist-item"
                v-for="(item, index) in countryList"
                :key="index"
                @click="selectCode(item.dial_code)"
              >
                <div
                  class="lookup-form-container-dropselect-container-item-span"
                  @mouseover="hover($event)"
                  @mouseleave="out($event)"
                >
                  <span>{{ item.dial_code }}</span>
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="lookup-form-container-continue-button">
          <!--
           :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"

          -->

          <button class="button-primary" id="lookupbutton" type="submit">
            {{ $t("continue") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { _providerId } from "@/provider-config.js";
import { mapGetters } from "vuex";
import { store } from "@/store/store";

export default {
  name: "lookup",
  props: {
    input: {
      type: String,
    },
  },
  data() {
    return {
      i18nEmailIdLabel: "",
      i18nMobilenumberLabel: "",
      searchCountryPlaceholder: "",
      payloadData: "",
      userInput: "",
      formError: "",
      switcher: "Mobile",
      field: "Email",
      teleCode: "+91",
      countrycode: "Please Select Country Code",
      countryCodes: [],
      digitCount: 0,
      isDropSelect: false,
      searchText: "",
      countryList: [],
      countryListCopy: [],
      providerUniqueId: _providerId,
      localDisplayLang: null,
      renderCountryCodeUI: false,
    };
  },
  computed: {
    ...mapGetters(["getRtl", "getCountry", "getCountryCodesList"]),
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = "";
        }, 5000);
      }
    },
    userInput(val) {
      if (val) {
        this.verifyInput(val);
      } else {
        this.verifyInput("xyz");
        this.searchText = "";
        this.filterCountry();
        this.fetchCurrentCountryCode();
      }
    },
    // field(val) {
    //   if (val === "Mobile") {
    //     this.teleCode = "+91";
    //   }
    // }
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.getCountryList();

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  components: {
    JhakaasInput: () => import("../../../utils/JhakaasInput.vue"),
  },
  mounted() {
    // FOr Test purpose

    // let info = {
    //   input: this.field === "Mobile" ? this.teleCode + this.userInput : this.userInput,
    //   inputtype: this.field,
    // };
    // info.formType = "socialCaptcha";
    // this.$emit("change", info);

    // -------

    eventBus.$on("social-captch-event", (data) => {
      

      let info = {
        data,
      };
      info.formType = "socialCaptcha";
      // this.formInfo = info;
      this.$emit("change", info);
    });

    this.i18nEmailIdLabel = this.$t("email id");
    this.i18nMobilenumberLabel = this.$t("mobilenumber");
    this.searchCountryPlaceholder = this.$t("search country");

    let self = this;

    window.addEventListener("mouseup", function(event) {
      if (self.field === "Mobile") {
        if (event.target.parentNode.id === "countrycode") {
          self.toggleDropSelect();
        } else if (
          !event.target.parentNode.id ||
          event.target.parentNode.id === "input-container"
        ) {
          if (self.isDropSelect) {
            self.isDropSelect = false;
            self.searchText = "";
            self.filterCountry();
            document.getElementById("arrow").style.transform = "rotate(0deg)";
          }
        }
      }
    });

    this.lookUpform();

    eventBus.$on("auth-close", () => {
      this.$emit("closePopup");
    });

    eventBus.$on("lookup-response", (response) => {
      if (response.reason) {
        this.formError = response.errorcode == 6066 ? "" : response.reason;
        // if (document.getElementById("lookupbutton")) {
        //   document.getElementById("lookupbutton").click();
        // }
      } else {
        let info = {
          input:
            this.field === "Mobile"
              ? this.teleCode + this.userInput
              : this.userInput,
          inputtype: this.field,
        };
        if (response.success) {
          info.formType = "login";
          this.$emit("change", info);
        } else {
          info.formType = "signup";
          this.$emit("change", info);
        }
      }
    });
  },
  methods: {
    getCountryList() {
      this.countryList = this.getCountryCodesList;
      this.countryListCopy = [...this.countryList];
      this.fetchCurrentCountryCode();
    },
    fetchCurrentCountryCode() {
      let index = this.countryList.findIndex((element) => {
        return element.code === this.getCountry.CountryCode;
      });

      if (index > -1) {
        this.teleCode = this.countryList[index].dial_code
          ? this.countryList[index].dial_code
          : "";
      }
    },
    lookUpform() {
      if (this.input) {
        this.userInput = this.input;
      }
    },
    verifyInput(value) {
      let digits = /^[0-9]+$/;
      let alphaNumeric = /^[0-9a-zA-Z]+$/;

      if (digits.test(value)) {
        this.field = "Mobile";
        this.renderCountryCodeUI = true;
        
        document.getElementById("userEmail").setAttribute("maxLength", 15);
        //    document.getElementById("userEmail").style.width = "80%";
        /*  
    if (document.getElementById("countrycode")) {
          document.getElementById("countrycode").style.display = "block";
         // document.getElementById("countrycode").style.width = "20%";
        }
        */
      } else if (alphaNumeric.test(value)) {
        this.field = "Email";
        this.renderCountryCodeUI = false;
        this.isDropSelect = false;
        document.getElementById("userEmail").removeAttribute("maxLength");
        // document.getElementById("userEmail").style.width = "100%";
        /*
       if (document.getElementById("countrycode")) {
          document.getElementById("countrycode").style.display = "none";
       //   document.getElementById("countrycode").style.width = "0%";
        }
        */
      }
    },
    countryCodeSelect(code) {
      this.teleCode = code;
      document.getElementById("input-container").style.display = "block";
      document.getElementById("expanded-container").style.display = "none";
    },
    toggleDropSelect() {
      this.isDropSelect = !this.isDropSelect ? true : false;
      if (this.isDropSelect) {
        document.getElementById("arrow").style.transform = "rotate(-180deg)";
      } else {
        document.getElementById("arrow").style.transform = "rotate(0deg)";
      }
    },
    selectCode(code) {
      
      this.teleCode = "";
      this.teleCode = code;
      this.isDropSelect = false;

      document.getElementById("arrow").style.transform = "rotate(0deg)";
    },
    hover(e) {
        if (
          e.target.parentNode.className ===
          "lookup-form-container-dropselect-container-item"
        ) {
          var color = "var(--clr-saina-country-code-select)";
         e.target.parentNode.style.backgroundColor = color;

        }
      },
      out(e) {
        if (
          e.target.parentNode.className ===
          "lookup-form-container-dropselect-container-item"
        ) {
          var color = "var(--clr-saina-country-fetch)";
          e.target.parentNode.style.backgroundColor = color;
        }
      },
    filterCountry() {
      let searchText = this.searchText.toLowerCase();
      if (
        searchText === "" ||
        searchText === null ||
        searchText === undefined
      ) {
        this.countryList = [...this.countryListCopy];
        return;
      }

      let arr = [];
      arr = this.countryListCopy.filter((element) => {
        return (
          element.name.toLowerCase().includes(searchText) ||
          element.dial_code.toLowerCase().includes(searchText)
        );
      });

      this.countryList = arr;
    },
    lookUpSubmit() {
      let payload = {};
      if (
        this.userInput.slice(0, 1) !== "+" &&
        this.userInput.indexOf("%") <= 0
      ) {
        if (this.field === "Email") {
          if (!this.validateEmail(this.userInput)) return;
          payload.email = this.userInput.trim();
        } else if (this.field === "Mobile") {
          payload.mobileno = this.teleCode + this.userInput;
        }
        eventBus.$emit("lookUp", payload);
      } else if (this.userInput.slice(0, 1) === "+") {
        this.formError = "Please remove '+' from Input";
      } else if (this.userInput.indexOf("%") > -1) {
        this.formError = "Valid Email Required";
      } else {
        // this.formError = "Please Enter Email ID / Mobile";
        this.formError = "Please Enter Email ID";
      }

      // if (this.userInput) {
      //   if (this.field === "Mobile") {
      //     payload.mobileno = this.teleCode + this.userInput;
      //   } else {
      //     payload.email = this.userInput;
      //   }
      //   eventBus.$emit("lookUp", payload);
      // } else {
      //   this.formError = "Please Enter Email ID / Mobile";
      // }
    },
  },
  mixins: [Utility],
  beforeDestroy() {
    eventBus.$off("mouseup");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./lookUp.scss"

</style>
